<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useUIStore } from "~/stores/ui";

const { mobile } = useDisplay();
const { isAppDrawerVisible, rightDrawer } = storeToRefs(useUIStore());
const { leftMenu } = storeToRefs(useUIStore());
const isShowLeftMenu = computed(() => (!mobile.value && leftMenu.value))
// cl(`layouts.dashboard-fluid`);
</script>

<template>
  <div>
    <v-app>
      <NavAppHeader />
      <div>

        <NavAppDrawer v-if="isAppDrawerVisible" />
        <NavAppDrawerFormMenu v-if="isShowLeftMenu" />
        <NavAppRightDrawer v-if="rightDrawer" />
      </div>
      <v-main>
        <v-container fluid
                     class="pl-10 pr-10 mb-10">
          <CommonAlert />
          <slot />
        </v-container>

      </v-main>
    </v-app>
  </div>
</template>
